import {useList} from "@refinedev/core";
import React, {FC, PropsWithChildren, useMemo} from "react";
import {
  CONTACTS,
  EBonusSystemType,
  LANGS,
  VIDEO_DIR
} from "types/constants";
import urlJoin from "url-join";


export interface State {
  settings: ISettings | null;
}


export interface ContextType extends State {
  onRefetchGlobalSettings: () => void
}

export interface ISettings {
  homeVideoUrl: string
  sounds: {
    productRemovalFromCart: {
      enabled: boolean
      fileUrl: string
    }
    paymentFailure: {
      enabled: boolean
      fileUrl: string
    }
  }
  enableSplashScreen: boolean
  languages: string[]
  moneyUnits: string
  country: string
  defaultLanguage: string
  productScanDelay: number
  productMarkingEnabled: boolean
  payWithBonusPointsOnly: boolean
  // Минимальная сумма в рублях (целое число), которую надо обязательно
  // оплатить реальным платежом (картой), даже если имеются еще бонусы
  minPaymentAmount: number
  bonusSystemType: EBonusSystemType | null
  bonusSystemHideName: boolean
  sbpQrEnabled: boolean
  sbpPaymentTimeout: number
  goPoedimBonusesEnabled: boolean
  goPoedimDisplayMaxBonusesByDefault: boolean
  obedruEnabled: boolean
  obedruDisplayMaxBonusesByDefault: boolean
  ozonBonusesEnabled: boolean
  ybadgeEnabled: boolean
  ybadgePaymentMinDelay: number
  yQrEnabled: boolean
  yEdaEnabled: boolean
  nalunchEnabled: boolean
  nalunchPaymentTimeout: number
  posTerminal: {
    paymentEnabled: boolean
  }
  maintenance: {
    activationCardNumbers: string[]
  }

  contact: {
    phone: string
    telegram: string
    email: string
  }

  micromarketNumber: string
  micromarketId: string
}


interface IRequestSettings {
  bonus_system_hide_name: boolean
  home_video_url: string
  maintenance: {
    activation_card_numbers: string[]
  }
  screensaver: {
    enabled: boolean,
    video_url: string
  }
  hide_splash_screen: boolean
  languages: string[]
  currency: string
  localization: {
    country: string
    default_language: string
  }
  min_payment_amount: number
  gopoedim_bonuses: {
    enabled: boolean,
    display_max_bonuses_by_default: boolean
  }
  obedru_enabled: boolean
  obedru: {
    enabled: boolean,
    display_max_bonuses_by_default: boolean
  }
  ozon_bonuses_enabled: boolean
  nalunch: { enabled: boolean, payment_timeout: number }
  pay_with_bonus_points_only: boolean
  pos_terminal: { payment_enabled: boolean }
  product_marking_enabled: boolean
  product_scan_delay: number
  sbp: { enabled: boolean, payment_timeout: number }
  sounds: {
    payment_failure: { enabled: boolean, file_url: string }
    product_removal_from_cart: { enabled: boolean, file_url: string }
    enabled: boolean
    file_url: string
  }
  ybadge_enabled: boolean
  ybadge_payment_min_delay: number
  yeda_enabled: boolean
  yqr_enabled: boolean

  contact: {
    phone: string,
    telegram: string,
    mail: string,
  }

  micromarket: {
    id: string
    number: string
  }
}

export const GlobalSettingsContext = React.createContext<ContextType | null>(null);

export const useSettings = () => {
  const {data, isFetching, ...props} = useList<IRequestSettings>({
    resource: "store/settings/",
    dataProviderName: "custom"
    // eslint-disable-next-line
  });

  const handleGlobalSettingsRefetch = async () => {
    await props?.refetch?.()
  }

  const formatter = (settings: IRequestSettings | null) => {
    if (!settings) {
      return null
    }

    const languages = LANGS[settings?.localization?.country || "RUS"] || ["ru", "en"]
    const defaultLanguage = languages?.includes(settings?.localization?.default_language) ?
      settings?.localization?.default_language : languages?.[0] || 'en';



    return {
      homeVideoUrl: urlJoin(VIDEO_DIR, settings?.screensaver?.video_url || ''),
      moneyUnits: settings?.localization?.country || "RUS",
      country: settings?.localization?.country || "RUS",
      languages: languages,
      defaultLanguage: defaultLanguage,
      sounds: {
        productRemovalFromCart: {
          enabled: !!settings?.sounds?.product_removal_from_cart?.enabled,
          fileUrl: settings?.sounds?.product_removal_from_cart?.file_url || '',
        },
        paymentFailure: {
          enabled: !!settings?.sounds?.payment_failure?.enabled,
          fileUrl: settings?.sounds?.payment_failure?.file_url || '',
        },
      },
      enableSplashScreen: settings?.screensaver?.enabled,
      productScanDelay: settings.product_scan_delay,
      productMarkingEnabled: settings.product_marking_enabled,
      payWithBonusPointsOnly: !!settings?.pay_with_bonus_points_only,
      // Минимальная сумма в рублях (целое число), которую надо обязательно
      // оплатить реальным платежом (картой), даже если имеются еще бонусы
      minPaymentAmount: settings.min_payment_amount,
      bonusSystemType: settings?.obedru?.enabled ? EBonusSystemType.OBEDRU : settings?.ozon_bonuses_enabled ?
        EBonusSystemType.OZON : settings.gopoedim_bonuses?.enabled ? EBonusSystemType.GOPOEDIM : null,
      bonusSystemHideName: settings.bonus_system_hide_name,
      sbpQrEnabled: !!settings.sbp?.enabled,
      sbpPaymentTimeout: settings.sbp?.payment_timeout || 60,
      goPoedimBonusesEnabled: settings.gopoedim_bonuses?.enabled,
      goPoedimDisplayMaxBonusesByDefault: settings.gopoedim_bonuses?.display_max_bonuses_by_default,
      obedruEnabled: settings?.obedru?.enabled,
      obedruDisplayMaxBonusesByDefault: settings?.obedru?.display_max_bonuses_by_default,
      ozonBonusesEnabled: settings.ozon_bonuses_enabled,
      ybadgeEnabled: settings.ybadge_enabled,
      ybadgePaymentMinDelay: settings.ybadge_payment_min_delay,
      yQrEnabled: settings.yqr_enabled,
      yEdaEnabled: settings.yeda_enabled,
      nalunchEnabled: !!settings.nalunch?.enabled,
      nalunchPaymentTimeout: settings.nalunch?.payment_timeout || 60,
      posTerminal: {
        paymentEnabled: !!settings.pos_terminal?.payment_enabled
      },
      maintenance: {
        activationCardNumbers: settings.maintenance?.activation_card_numbers || [],
      },
      contact: CONTACTS[settings?.localization?.country || "RUS"],
      micromarketNumber: settings?.micromarket?.number || 0,
      micromarketId: settings?.micromarket?.id || ''
    } as ISettings
  }

  return {
    // @ts-ignore
    settings: useMemo(() => formatter(data?.data || null), [data]),
    isFetching: false,
    onRefetchGlobalSettings: handleGlobalSettingsRefetch
  };
};

export const GlobalSettingsProvider: FC<PropsWithChildren> = (props) => {

  const {settings, onRefetchGlobalSettings} = useSettings();

  const value = useMemo(() => ({
      settings: settings,
      onRefetchGlobalSettings
  }), [settings])

  // @ts-ignore
  return <GlobalSettingsContext.Provider value={value} {...props} />;
};

export const useGlobalSettings = (): ContextType => {
  const context = React.useContext(GlobalSettingsContext);
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`);
  }
  return context as ContextType;
};

export const ManagedGlobalSettingsContext: FC<PropsWithChildren> = ({children}) => (
  <GlobalSettingsProvider>
    {children}
  </GlobalSettingsProvider>
);
