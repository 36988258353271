import urlJoin from "url-join";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL || '/';
const API_PREFIX = '/api/v1';

export const API_URL = urlJoin(BACKEND_BASE_URL, API_PREFIX);

export const MONEY_UNIT = process.env.REACT_APP_MONEY_UNITS || "₽"

export const INACTIVITY_TIME_SEC: number = process.env.REACT_APP_INACTIVITY_TIME_SEC ? parseInt(process.env.REACT_APP_INACTIVITY_TIME_SEC) : 35;
export const SENDING_EMAIL_TIME_SEC: number = process.env.REACT_APP_SENDING_EMAIL_TIME_SEC ? parseInt(process.env.REACT_APP_SENDING_EMAIL_TIME_SEC) : 4;
export const SCANNING_CODE_TIMEOUT_MS: number = process.env.REACT_APP_SCANNING_CODE_TIMEOUT_MS ? parseInt(process.env.REACT_APP_SCANNING_CODE_TIMEOUT_MS) : 100;
export const HEALTH_CHECK_DATA_TIME_SEC: number = 60;
export const REFETCH_DATA_TIME_SEC: number = process.env.REACT_APP_REFETCH_DATA_TIME_SEC ? parseInt(process.env.REACT_APP_REFETCH_DATA_TIME_SEC) :  30;
export const ABORT_PAYMENT_TIME_SEC: number = 60;

export const PRODUCT_IMAGES_DIR = process.env.REACT_APP_PRODUCT_IMAGES_DIR || '/';
export const SOUNDS_DIR = process.env.REACT_APP_SOUNDS_DIR || '/';
export const VIDEO_DIR = process.env.REACT_APP_VIDEO_DIR || '/';

export const EMAIL_QR_CODE_LINK = process.env.REACT_APP_EMAIL_QR_CODE_LINK || 'help@vendagroup.ru';
export const TELEGRAM_QR_CODE_LINK = process.env.REACT_APP_TELEGRAM_QR_CODE_LINK || 't.me/gogopoedim';
export const SUPPORT_PHONE = process.env.REACT_APP_SUPPORT_PHONE || '8 800 222 00 69';

export const CONTACTS: { [key: string]: {
    phone: string,
    telegram: string,
    email: string
  }} = {
  RUS: {
    phone: '8 800 222 00 69',
    telegram: 't.me/gogopoedim',
    email: 'help@vendagroup.ru'
  },
  SRB: {
    phone: '+381616595805',
    telegram: 't.me/gogopoedim',
    email: 'goeatserbia@gmail.com'
  },
  UAE: {
    phone: '8 800 222 00 69',
    telegram: 't.me/gogopoedim',
    email: 'help@vendagroup.ru'
  },
}

export const BONUS_QR_CODE_LINK = process.env.REACT_APP_BONUS_QR_CODE_LINK || 'https://gopoedim.app.link';

export const SUPPORTED_LANGS = process.env.REACT_APP_SUPPORTED_LANGS?.split(',') || ['ru', 'en', 'ar', 'sr']
export const FALLBACK_LANGS = process.env.REACT_APP_FALLBACK_LANGS?.split(',') || ['ru', 'en', 'ar', 'sr']
export const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG || 'en'

export const LANGS: { [key: string]: string[]} = {
  RUS: ['ru', 'en'],
  SRB: ['en', 'sr'],
  UAE: ['en', 'ar'],
}

export enum ERRORS {
  SOMETHING_WENT_WRONG = 'somethingWentWrong',
  TERMINAL_TIMEOUT = 'PosTerminalTransactionTimeoutExpired',
  CARD_NOT_WORK = 'cardNotWork',
  TIMEOUT_REQUEST_ERROR = 'timeoutRequest',
  MARKED_PRODUCT_IS_ADDED = 'marketProductIsAdded',
  PROMO_CODE_IS_NOT_EXIST = 'promoCodeNotExist',
  PROMO_CODE_INVALID = 'promoCodeInvalid',
  PROMO_CODE_NOT_UNIQUE = 'promoCodeNotUnique',
  NA_LUNCH_PAYMENT_FAIL = 'naLunchPaymentFail',
  YANDEX_PAYMENT_FAIL = 'yandexPaymentFail',
  YANDEX_BADGE_PAYMENT_FAIL = 'yandexBadgePaymentFail',
  YANDEX_EDA_PAYMENT_FAIL = 'yandexEdaPaymentFail',
}

export const ERROR_CODES: {
  [key: number]: ERRORS
} = {
  1000: ERRORS.SOMETHING_WENT_WRONG,
  1048: ERRORS.YANDEX_PAYMENT_FAIL,
  1055: ERRORS.NA_LUNCH_PAYMENT_FAIL,
  1100: ERRORS.PROMO_CODE_INVALID,
  1101: ERRORS.PROMO_CODE_NOT_UNIQUE
}

export enum EPaymentSteps {
  ADDITIONAL_PRODUCT = 'additionalProducts',
  PAYMENT_METHODS = 'selectPaymentMethod',
  WRITE_OFF_BONUSES = 'writeOffBonuses',
  FINISH_PAYMENT = 'finishPayment',
}

export enum EPaymentMethods {
  PAYMENT_TYPE_CARD = 'card',
  PAYMENT_TYPE_SBP = 'sbp',
  PAYMENT_TYPE_ON_LUNCH = 'onLunch',
  PAYMENT_TYPE_YANDEX_BADGE = 'yandexBadge',
  PAYMENT_TYPE_YANDEX_QR = 'yandexQR',
  PAYMENT_TYPE_YANDEX_EDA = 'lunchCart'
}

export enum EBonusSystemType {
  GOPOEDIM = 'gopoedim',
  OBEDRU = 'obedru',
  OZON = 'ozon',
}
